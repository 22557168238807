import { PublicJobBoardOpportunityTableView, GetPublicJobBoardOpportunitiesTable, OrganizationJobDetails } from 'features/opportunities/types';
import { TableDataResponse } from 'features/types';
import { client, withDataRequest, withFormDataRequest } from 'utils/client';
import { GetSelectList, DataResponse, SelectItem } from './types';

const opportunityAPI = {
  getOpportunities(params: GetPublicJobBoardOpportunitiesTable) {
    return client.post<TableDataResponse<PublicJobBoardOpportunityTableView>>('/opportunities/get-table', withDataRequest(params));
  },
  getPublicJobBoardOpportunity(jobId: string) {
    return client.get<PublicJobBoardOpportunityTableView>(`/opportunities/${jobId}`);
  },
  respond(params: FormData, jobId: string) {
    return client.post<void>(`/opportunities/${jobId}/respond`, withFormDataRequest(params));
  },
  getPublicJobDetails(jobId: string) {
    return client.get<OrganizationJobDetails>(`/opportunities/${jobId}/details`);
  },
  increaseViewsCount(id: string) {
    return client.post<void>(`/opportunities/${id}/job-seeker-increase-job-views-count`);
  },
  getSelectList(params: GetSelectList) {
    return client.post<DataResponse<SelectItem>>('/opportunities/get-job-types-select-list', withDataRequest(params));
  },
};

export default opportunityAPI;
